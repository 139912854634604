<template>
  <div>
    <Form :options="formOptions"></Form>
  </div>
</template>
<script>
import Form from '@/components/form/Form.vue'
import axios from '@/components/axios'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  data () {
    return {
      formOptions: {
        formTitle: `${this.$route.query.id ? 'Edit Opening Lead' : 'Add Opening Lead'}`,
        submitRouterPath: '/master/account',
        gridForm: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/openingland`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Type',
            name: 'type',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            options: [
              { name: 'Other' },
              { name: 'Supplier' },
              { name: 'Customer' },
              { name: 'Bank' },
              { name: 'Cash' },
              { name: 'Asset' },
              { name: 'Dealer' },
              { name: 'Employee' }
            ]
            // required: {
            //   required,
            // },
          },
          {
            label: 'Account Group',
            name: 'chartofaccount',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'chartofaccount',
            url: 'chartofaccount',
            options: []
            // required: {
            //   required,
            // },
          },
          {
            label: 'Title',
            name: 'title',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            options: [
              { name: 'DR.' },
              { name: 'MRS.' },
              { name: 'M/S.' },
              { name: 'MS.' },
              { name: 'Mr.' },
              { name: 'Er.' },
              { name: 'Prof.' },
              { name: 'Shri' }
            ]
            // required: {
            //   required,
            // },
          },
          {
            label: 'Ledger Name',
            name: 'ledgername',
            value: '',
            type: 'text',
            placeholder: 'Enter Ledger Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Mobile',
            name: 'mobile',
            value: '',
            type: 'number',
            placeholder: 'Enter Mobile Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Alias',
            name: 'Alias',
            value: '',
            type: 'text',
            placeholder: 'Enter Alias',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Registration Type',
            name: 'registrationtype',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'registrationtype',
            options: [
              { name: 'None' },
              { name: 'Compositions' },
              { name: 'Regular' },
              { name: 'Consumer' },
              { name: 'Unregistered' }
            ]
            // required: {
            //   required,
            // },
          },
          {
            label: 'GSTIN NO',
            name: 'gstinno',
            value: '',
            type: 'number',
            placeholder: 'Enter GSTIN Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Pan NO',
            name: 'panno',
            value: '',
            type: 'number',
            placeholder: 'Enter Pan Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Birth Date',
            name: 'birthdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Select Birth date',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Swift Code',
            name: 'chequeno',
            value: '',
            type: 'text',
            placeholder: 'Enter Swift Code',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'IFSC Code',
            name: 'ifsccode',
            value: '',
            type: 'text',
            placeholder: 'Enter IFSC Code',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Bank',
            name: 'bank',
            value: '',
            type: 'text',
            placeholder: 'Enter Bank Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Branch',
            name: 'Branch',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Branch Name'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Account No',
            name: 'accountno',
            value: '',
            type: 'number',
            placeholder: 'Enter Account No',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Tan No',
            name: 'tanno',
            value: '',
            type: 'Number',
            placeholder: 'Enter Tan No',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Beneficiary Name',
            name: 'beneficiaryname  ',
            value: '',
            type: 'text',
            placeholder: 'Enter Beneficiary Name',
            class: 'col-md-12'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Contry',
            name: 'contry',
            value: '',
            type: 'text',
            placeholder: 'Enter Country Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'TDS%',
            name: 'tds',
            value: '',
            type: 'number',
            placeholder: 'Enter TDS %',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Address Line 1',
            name: 'adr1',
            value: '',
            type: 'text',
            placeholder: 'Enter Address Line 1',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Address Line 2',
            name: 'adr2',
            value: '',
            type: 'text',
            placeholder: 'Enter Address Line 2',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Address Line 3',
            name: 'adr3',
            value: '',
            type: 'text',
            placeholder: 'Enter Address Line 3',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Address Line 4',
            name: 'adr4',
            value: '',
            type: 'text',
            placeholder: 'Enter Address Line 4',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Address Line 5',
            name: 'adr5',
            value: '',
            type: 'text',
            placeholder: 'Enter Address Line 5',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Area',
            name: 'area',
            value: '',
            type: 'text',
            placeholder: 'Enter Area',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter City',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'number',
            placeholder: 'Enter Pin Code',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter State',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Active/Inactive',
            name: 'activeinactive',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'activeinactive',
            options: [{ name: 'Active' }, { name: 'Inactive' }]
            // required: {
            //   required,
            // },
          },
          {
            label: 'Contact Person Name',
            name: 'contactname',
            value: '',
            type: 'text',
            placeholder: 'Enter Contact Person Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Contact Person Number',
            name: 'contactnumber',
            value: '',
            type: 'number',
            placeholder: 'Enter Contact Person Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Credit period(In Days)',
            name: 'creditperiod',
            value: '',
            type: 'text',
            placeholder: 'Enter Credit Period In Days',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'RCM',
            name: 'rcm',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'rcm',
            options: [{ name: 'Yes' }, { name: 'No' }]
            // required: {
            //   required,
            // },
          },
          {
            label: 'Select Employee',
            name: 'employee',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'employee',
            options: []
            // required: {
            //   required,
            // },
          },
          {
            label: 'Username',
            name: 'username',
            value: '',
            type: 'text',
            placeholder: 'Enter Username',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Password',
            name: 'password',
            value: '',
            type: 'text',
            placeholder: 'Enter Password',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Photo',
            name: 'photo',
            value: '',
            type: 'fileinput',
            placeholder: 'select Photo',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: 'Gender',
            name: 'gender',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'gender',
            options: [{ name: 'Male' }, { name: 'Female' }]
            // required: {
            //   required,
            // },
          },
          {
            label: 'Coupon',
            name: 'coupon',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'coupon',
            options: [{ name: 'Yes' }, { name: 'No' }]
            // required: {
            //   required,
            // },
          }
        ]
      }
    }
  },
  components: {
    Form
  },
  beforeMount () {
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  },
  mounted () {}
}
</script>
